import React, { Component } from "react";
import { connect } from "react-redux";
import { CheckboxRadioRow, FormLabel, FormWidget, FormFeedback } from "../widgets";

class FormGridRowComponent extends Component {
    render() {
        const props = this.props;
        let { spacer, type } = props;
        if (type === "checkbox" || type === "radio") {
            return <CheckboxRadioRow {...props} />;
        }
        return [
            spacer && <hr key="spacer" style={{ marginTop: "0.2rem", marginBottom: "0.2rem" }} />,
            <div key="question" className={`form-row`}>
                <FormLabel {...props} />
                <div className="col col-sm-6 col-md-8">
                    <FormWidget {...props} />
                    <FormFeedback {...props} />
                </div>
            </div>
        ];
    }
}
const FormGridRow = connect(({ assessment: { field_types } }) => ({ field_types }))(FormGridRowComponent);

class FormRowComponent extends Component {
    render() {
        const { type } = this.props;

        if (type === "checkbox" || type === "radio") {
            return <FormGridRow {...this.props} />;
        }
        if (type === "switch") {
            if (this.props.try_inline) {
                return (
                    <div className="form-group row">
                        {this.props.label && <FormLabel {...this.props} className={false} />}
                        <FormWidget {...this.props} style={{ marginLeft: 0, marginRight: 0 }} />
                        <FormFeedback {...this.props} />
                    </div>
                );
            }
            return <FormGridRow {...this.props} />;
        }
        return (
            <div className={`form-group`}>
                {this.props.label && <FormLabel {...this.props} className={false} />}
                <FormWidget {...this.props} />
                <FormFeedback {...this.props} />
            </div>
        );
    }
}

const FormRow = FormRowComponent;

export { FormGridRow, FormRow };
