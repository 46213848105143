const helpers = {
    get language() {
        const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
        const i18n = {
            language: language.toLowerCase().split(/[_-]+/)[0] || "nl",
            defaultLanguage: "nl",
            supportedLanguages: ["nl"]
        };
        i18n.userLanguage = i18n.supportedLanguages.indexOf(i18n.language) !== -1 ? i18n.language : i18n.defaultLanguage;
        return i18n;
    },
    get host() {
        if (sessionStorage.getItem("API_HOST")) {
            return sessionStorage.getItem("API_HOST");
        }
        const { hostname, protocol } = (window || { location: "" }).location;
        let backendHost;
        if (hostname === "127.0.0.1" || hostname === "localhost") {
            backendHost = process.env.REACT_APP_API_ROOT;
        } else {
            backendHost = `${protocol}//${hostname}`;
        }
        sessionStorage.setItem("API_HOST", backendHost);
        return backendHost;
    }
};
const API_PATH = "/api";
const API_HOST = helpers.host;
const APP_LANGUAGE = helpers.language.userLanguage;
const FALLBACK_LANGUAGE = helpers.language.defaultLanguage;
export { API_HOST, API_PATH, APP_LANGUAGE, FALLBACK_LANGUAGE };
