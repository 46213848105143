import { OtherWidget } from "instruments/widgets";
import React, { Component } from "react";
import { Field, FormSection } from "redux-form";

class MatrixQuestion extends Component {
    render() {
        const { choices } = this.props;
        return (
            <>
                <table className="table table-responsive">
                    <thead>
                        <tr>
                            <th>{""}</th>
                            {choices.map(choice => (
                                <th key={choice.code}>{choice.descriptiveTag}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.subQuestions.map(quest => (
                            <FormSection component="tr" name={quest.code} key={quest.code}>
                                <td>{quest.label}</td>
                                {quest.choices.map(option => (
                                    <td key={option.code}>
                                        <label>
                                            <Field name={option.code} component={OtherWidget} type="checkbox" value={`${option.code}`} />
                                        </label>
                                    </td>
                                ))}
                            </FormSection>
                        ))}
                    </tbody>
                </table>
            </>
        );
    }
}

export default MatrixQuestion;
