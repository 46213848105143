import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormSection } from "redux-form";
import { connect } from "react-redux";
import { QuestionBlock, HeaderBlock, NoticeBlock } from ".";

class FormBlockComponent extends Component {
    static propTypes = {
        questions: PropTypes.arrayOf(
            PropTypes.shape({ label: PropTypes.string.isRequired, name: PropTypes.string.isRequired, type: PropTypes.string, header: PropTypes.bool })
        ).isRequired
    };
    render() {
        const { page, questions } = this.props;
        const pomaQuestions = questions.reduce((outList, question) => {
            if (question.extra && question.extra.viewdata && question.extra.viewdata.view_type === "table") {
                const otherQuestions = outList.filter(q => !(q.extra && q.extra.viewdata && q.extra.viewdata.view_type === "table"));
                const tableQuestion = outList.find(q => q.extra && q.extra.viewdata && q.extra.viewdata.view_type === "table");
                outList = [
                    ...otherQuestions,
                    { ...question, ...tableQuestion, subQuestions: [...((tableQuestion || {}).subQuestions || []), question] }
                ];
            } else {
                outList.push(question);
            }
            return outList;
        }, []);
        return (
            <FormSection name={page}>
                {pomaQuestions.map(question =>
                    question.header === true ? (
                        <HeaderBlock key={question.name} {...question} />
                    ) : question.notice === true ? (
                        <NoticeBlock key={question.name} {...question} />
                    ) : (
                        <QuestionBlock key={question.name} {...question} />
                    )
                )}
            </FormSection>
        );
    }
}

const mapStateToProps = ({ login: { authenticated }, assessment: { content = {}, tabs = [] }, instrument: { instruments } }, { page }) => {
    return {
        page,
        questions: (instruments.find(({ code }) => code === authenticated.instrument).items || [])
            .filter(item => item.extra && item.extra.page === page)
            .map(item => ({ label: item.descriptiveTag, name: item.code, ...item }))
    };
};
const mapPropsToDispatch = dispatch => ({});

export default connect(mapStateToProps, mapPropsToDispatch)(FormBlockComponent);
