import React, { Component } from "react";
import { FormFeedback } from "instruments/widgets";

class RadioWidget extends Component {
    render() {
        const { choices, input, meta, noLabel, extra } = this.props;
        return (
            <>
                <div id={input.code} key={input.code}>
                    {choices.map(option => (
                        <div className={extra.viewdata.view_type === "vertical_list" ? "vertical-list" : "d-inline-flex ml-4"} key={option.code}>
                            <label>
                                <input {...input} type="radio" value={`${option.value}`} checked={`${option.value}` === `${input.value}`} />
                                {noLabel === true ? "" : option.descriptiveTag}
                            </label>
                        </div>
                    ))}
                </div>
                <FormFeedback {...{ input, meta }} />
            </>
        );
    }
}

export default RadioWidget;
