import React, { Component } from "react";
import Logo from "components/shared/Logo";

class CenterBlock extends Component {
    render() {
        const { children, message } = this.props;
        return (
            <div className="container mx-auto">
                <div className="d-flex row align-items-center" style={{ height: "100vh" }}>
                    <div className="col-sm-10 col-md-6 col-lg-5 col-xl-4 mx-auto">
                        <div className="card bg-white">
                            <div className="card-body">
                                <Logo />
                                <hr />
                                {message && <p className="m-1 pb-3 px-1 text-center">{message}</p>}
                                {!message && children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CenterBlock;
