import React from "react";
import { Route } from "react-router";
import { AssessmentFillPage, AssessmentAuthenticate } from "components/assessment";
import { LoggedOutPage, MessagePage } from "components/security";

const userRoutes = [];
const unsecuredRoutes = [
    <Route path="/assessmentLogin/:token" component={AssessmentAuthenticate} key="assessment-token" />,
    <Route path="/assessment/:id" component={AssessmentFillPage} key="assessment-fill" />,
    <Route path="/logged_out" component={LoggedOutPage} key="logged-out" />,
    <Route path="/" component={MessagePage} key="fallback" />
];

export { unsecuredRoutes, userRoutes };
