import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { rootReducer } from "_reducers";
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { middleware as flashMiddleware } from "redux-flash";
import { APP_LANGUAGE, FALLBACK_LANGUAGE } from "_utils/_entrypoint";

import messages_nl from "translations/nl.json";

const messages = { nl: messages_nl };

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem("APP_STATE");
        return serializedState === null ? undefined : JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveState = state => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("APP_STATE", serializedState);
    } catch (err) {}
};

export const defaultState = {
    ...loadState(),
    intl: { defaultLocale: FALLBACK_LANGUAGE, locale: APP_LANGUAGE, messages: messages[APP_LANGUAGE] },
    login: {
        authenticated: {
            ...(sessionStorage.getItem("APP_TOKENS") ? JSON.parse(sessionStorage.getItem("APP_TOKENS")) : {})
        }
    }
};

const history = createBrowserHistory();
const devEnhancer = compose(
    applyMiddleware(
        routerMiddleware(history),
        thunkMiddleware,
        createLogger({
            collapsed: true,
            predicate: (getState, action) => action.type !== "@@redux-form/REGISTER_FIELD"
        }),
        flashMiddleware()
    )
);
const prodEnhancer = compose(applyMiddleware(routerMiddleware(history), thunkMiddleware, flashMiddleware()));

const store = createStore(
    connectRouter(history)(rootReducer),
    defaultState,
    !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? devEnhancer : prodEnhancer
);

store.subscribe(() => {
    saveState({});
});

export { store, history, rootReducer };
