import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "assets/scss/index.scss";
import "typeface-roboto";

import promiseFinally from "promise.prototype.finally";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-intl-redux";
import { store, history } from "_utils";
import { unregister } from "registerServiceWorker";

import { addLocaleData } from "react-intl";
import locale_en from "react-intl/locale-data/en";
import locale_nl from "react-intl/locale-data/nl";

import App from "components/App";
import NavigationMenu from "components/shared/NavigationMenu";
import FlashMessages from "components/shared/FlashMessages";

addLocaleData([...locale_en, ...locale_nl]);

promiseFinally.shim();

const render = () => {
    ReactDOM.render(
        <Provider store={store}>
            <div>
                <NavigationMenu />
                <FlashMessages />
                <App history={history} />
            </div>
        </Provider>,
        document.getElementById("root")
    );
};

render();

unregister();
