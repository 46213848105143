import React from "react";
import { connect } from "react-redux";
import { flashMessage } from "redux-flash";
import { reduxForm, submit, touch } from "redux-form";
import "instruments/Questionnaire.scss";
import Form from "instruments/Form";
import ButtonBar from "instruments/ButtonBar";

const QuestionnaireComponent = ({ manualSendToServer, form, allValues, remoteTriggerSubmit, setFinal, remoteSubmit, url }) => {
    const doRemoteSubmit = a => {
        setFinal(a.final, form);
        if (a.final) {
            remoteTriggerSubmit(form);
        } else {
            manualSendToServer(allValues, {
                remoteSubmit,
                final: false,
                url,
                form
            });
        }
    };

    return (
        <form>
            <Form />
            <ButtonBar remoteSubmit={doRemoteSubmit} />
        </form>
    );
};

let reduxFormObject = reduxForm()(QuestionnaireComponent);

const mapFormStateToProps = (state, ownProps) => {
    const {
        assessment: { final, answers = {} },
        form
    } = state;
    return {
        allValues: (form[ownProps.formName || "generic_questionnaire"] || {}).values,
        initialValues: answers,
        form: "generic_questionnaire",
        final
    };
};
const mapDispatchToProps = dispatch => {
    return {
        remoteTriggerSubmit: formName => dispatch(submit(formName)),
        setFinal: (final, formName) => {
            dispatch({ type: "FORM_FINAL", final });
            dispatch(touch(formName));
        },
        manualSendToServer: (formData, ownProps) => {
            const { remoteSubmit, url, form } = ownProps;
            remoteSubmit({ url: url, final: false, formName: form, ...formData });
        },
        onSubmit: (formData, b, ownProps) => {
            const { remoteSubmit, url, final, form } = ownProps;
            remoteSubmit({ url, final, formName: form, ...formData });
        },
        onSubmitFail: (a, b, c) => {
            dispatch(
                flashMessage(
                    "Let op er zijn nog verplichte vragen die nog niet ingevuld zijn. Deze graag invullen voordat de vragenlijst definitief opgeslagen kan worden.",
                    { props: { color: "danger" } }
                )
            );
        }
    };
};
const Questionnaire = connect(mapFormStateToProps, mapDispatchToProps)(reduxFormObject);

export default Questionnaire;
