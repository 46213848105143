import PropTypes from "prop-types";
import React, { Component } from "react";
import "./style.scss";

class BsToggle extends Component {
    state = { enabled: this.enabledFromProps(), propsWasEnabled: this.enabledFromProps() };
    static propTypes = {
        theme: PropTypes.string,
        enabled: PropTypes.bool.isRequired,
        readOnly: PropTypes.bool,
        onStateChanged: PropTypes.func,
        positiveLabel: PropTypes.string,
        negativeLabel: PropTypes.string
    };
    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.propsWasEnabled !== nextProps.enabled) {
            return { enabled: nextProps.enabled, propsWasEnabled: nextProps.enabled };
        }
        return prevState;
    }

    isEnabled = () => this.state.enabled;

    enabledFromProps() {
        let { enabled } = this.props;
        return !!enabled;
    }
    toggleSwitch = evt => {
        if (this.props.readOnly) {
            return;
        }
        evt.persist();
        evt.preventDefault();

        const { onClick, onStateChanged } = this.props;

        this.setState({ enabled: !this.state.enabled }, () => {
            const state = this.state;

            const switchEvent = Object.assign(evt, { SWITCH_STATE: state });
            if (onClick) {
                onClick(switchEvent);
            }
            if (onStateChanged) {
                onStateChanged(state);
            }
        });
    };
    render() {
        // Isolate special props and store the remaining as restProps
        const { enabled, name, theme, onClick, className, onStateChanged, negativeLabel, readOnly, positiveLabel, ...restProps } = this.props;

        // Use default as a fallback theme if valid theme is not passed
        const switchTheme = theme ? theme : "default";

        const switchClasses = `align-middle switch switch--${switchTheme} ${className || ""} ${readOnly ? "switch--readonly" : ""}`;

        const togglerClasses = `switch-toggle switch-toggle--${this.isEnabled() ? "on" : "off"}`;

        return [
            negativeLabel !== "\u00A0" && (
                <span key="label-negative" className="d-inline-block align-middle">
                    {negativeLabel}
                </span>
            ),
            <div key="switch" className={switchClasses} onClick={this.toggleSwitch} {...restProps}>
                <div className={togglerClasses} />
            </div>,
            positiveLabel !== "\u00A0" && (
                <span key="label-positive" className="d-inline-block align-middle">
                    {positiveLabel}
                </span>
            )
        ];
    }
}
export default BsToggle;
