import { combineReducers } from "redux";
import { intlReducer } from "react-intl-redux";
import { reducer as flashReducer } from "redux-flash";
import * as assessment from "_reducers/assessment";
import * as security from "_reducers/security";

import { reducer as formReducer } from "redux-form";

const rootReducer = combineReducers({
    ...assessment,
    ...security,
    userPermissions: (state = []) => state,
    visualState: combineReducers({
        page: (state = "0", action = {}) => (action.type === "PAGE_CHANGE" ? action.payload : state),
        expanded: (state = null, action = {}) => {
            if (action.type !== "ACCORDION_CHANGE") {
                return state;
            }
            return action.payload === state ? null : action.payload;
        },
        valid: (state = true, action = {}) => {
            if (action.type === "PAGE_INVALID") {
                return false;
            }
            if (action.type === "PAGE_VALID") {
                return true;
            }
            return state;
        },
        url: (state = null, action = {}) => (action.type === "CHANGE_SUBMIT_URL" ? action.url : state)
    }),
    flash: flashReducer,
    form: formReducer,
    intl: intlReducer
});

export { rootReducer };
