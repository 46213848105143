import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, formValueSelector, FieldArray } from "redux-form";
import { connect } from "react-redux";
import { FormGridRow } from "./FormFields";
import { ReduxCheckbox, Checkboxes, FormLabel, RadioWidget, MatrixQuestion } from "instruments/widgets";
import { required } from "instruments/validation";

class QuestionBlockComponent extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        conditionMet: PropTypes.bool.isRequired,
        condition: PropTypes.shape({
            field: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            values: PropTypes.array
        })
    };

    render() {
        if (!this.props.conditionMet) {
            return null;
        }
        const extraOptions = this.props.extra;
        const choices = this.props.choices.map(choice => ({ label: choice.descriptiveTag, ...choice }));
        if (
            extraOptions &&
            extraOptions.viewdata &&
            extraOptions.viewdata.view_type === "vertical_list" &&
            extraOptions.viewdata.multiple === "true"
        ) {
            return (
                <div key={choices.descriptiveTag} className={`form-group row vertical-checkbox`}>
                    <FormLabel {...this.props} />
                    <div className="col-4">
                        <Field component={ReduxCheckbox(Checkboxes)} {...{ data: choices, ...this.props }} validate={[required]} />
                    </div>
                </div>
            );
        }
        if (extraOptions && extraOptions.viewdata && extraOptions.viewdata.view_type === "vertical_list") {
            return (
                <div className={`form-group row vertical-checkbox`}>
                    <FormLabel className="label-equal col" {...this.props} />
                    <div className="col-4">
                        <Field component={RadioWidget} {...{ choices, ...this.props }} validate={[required]} />
                    </div>
                </div>
            );
        }
        if (extraOptions && extraOptions.viewdata && extraOptions.viewdata.view_type === "table") {
            const { choices } = this.props;
            return (
                <FieldArray name="matrix-question" component="div" className="form-group row matrix">
                    <div className="col-12">
                        <div>{extraOptions.viewdata.header}</div>
                        <br />
                        <Field component={MatrixQuestion} {...{ choices, ...this.props }} type="checkbox" />
                    </div>
                </FieldArray>
            );
        }
        if (
            (extraOptions && extraOptions.viewdata && extraOptions.viewdata.view_type === "yes_no") ||
            (extraOptions && extraOptions.viewdata && extraOptions.viewdata.view_type === "yes_no_nvt")
        ) {
            return (
                <div className={`form-group row yes-no`}>
                    <FormLabel className="label-equal col" {...this.props} />
                    <div className="col-4">
                        <Field component={RadioWidget} {...{ choices, ...this.props }} validate={[required]} />
                    </div>
                </div>
            );
        }
        if (extraOptions && extraOptions.viewdata && extraOptions.viewdata.view_type === "label") {
            return <div>lol</div>;
        }
        return <Field component={FormGridRow} {...this.props} validate={[required]} />;
    }
}

const selector = formValueSelector("generic_questionnaire");

const mapStateToProps = (state, ownProps) => {
    return {
        conditionMet: !ownProps.condition || selector(state, ownProps.condition.field) === ownProps.condition.value
    };
};
const mapPropsToDispatch = dispatch => ({});

export default connect(mapStateToProps, mapPropsToDispatch)(QuestionBlockComponent);
