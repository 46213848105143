import fecha from "fecha";
import React from "react";
import { API_PATH } from "_utils/_entrypoint";
import { FormattedMessage } from "react-intl";

const createLink = item => {
    if ("string" !== typeof item || !item.includes(API_PATH)) {
        return <div key={item}>{item}</div>;
    }

    const routeWithoutPrefix = item.replace(API_PATH, "");
    const splittedRoute = routeWithoutPrefix.split("/");
    const route = "/" === routeWithoutPrefix[0] ? splittedRoute[1] : splittedRoute[0];

    return (
        <div key={item}>
            <a href={`/${route}/show/${encodeURIComponent(item)}`}>{item}</a>
        </div>
    );
};

export const itemToLinks = items => {
    return Array.isArray(items) ? items.map(item => createLink(item)) : createLink(items);
};

export const getIdFromIri = uri => {
    const parts = uri.split("/");
    const out = parseInt(parts[parts.length - 1], 10);
    return isNaN(out) ? parts[parts.length - 1] : out;
};

const formats = ["YYYY-MM-DDTHH:mm:ssZZ", "YYYY-MM-DDTHH:mm:ss", "YYYY-MM-DD HH:mm:ssZZ", "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD"];
export const toDate = v => {
    if (!v || v instanceof Date) return v || null;
    return formats.reduce((out, format) => out || fecha.parse(v, format), null);
};

export const dateDiff = (dateA, dateB) => {
    if (!dateA || !dateB) {
        return null;
    }
    return Math.ceil((toDate(dateB) - toDate(dateA)) / (1000 * 3600 * 24));
};

export const toDateString = (date, format = "DD-MM-YYYY") => {
    if (!date) {
        return null;
    }
    if (date instanceof Date) {
        return fecha.format(date, format);
    }
    return fecha.format(toDate(date), format);
};

export const toUTCDate = date => {
    if (!(date instanceof Date)) {
        date = toDate(date);
    }
    if (!date) {
        return date;
    }
    return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
};

export const tableConfig = () => ({
    showPageSizeOptions: false,
    defaultPageSize: 15,
    resizable: false,
    className: "-striped",
    noDataText: <FormattedMessage id="table.noDataText" defaultMessage="No results found" />,
    previousText: <FormattedMessage id="table.previousText" defaultMessage="Previous" />,
    nextText: <FormattedMessage id="table.nextText" defaultMessage="Next" />,
    loadingText: <FormattedMessage id="table.loadingText" defaultMessage="Loading..." />,
    pageText: <FormattedMessage id="table.pageText" defaultMessage="Page" />,
    ofText: <FormattedMessage id="table.ofText" defaultMessage="of" />,
    rowsText: <FormattedMessage id="table.rowsText" defaultMessage="Results" />
});

export const colorAdjust = (color, amount) => {
    var usePound = false;

    if (color[0] === "#") {
        color = color.slice(1);
        usePound = true;
    }

    var num = parseInt(color, 16);

    var r = (num >> 16) + amount;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amount;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amount;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};
