import fetch from "_utils/fetch";
import { flashMessage } from "redux-flash";
import { getIdFromIri } from "_utils";
import { logout } from "_actions/security/login";
import { formValueSelector } from "redux-form";

export const ASSESSMENT_FAILURE = "ASSESSMENT_FAILURE";
export const ASSESSMENT_LOADING = "ASSESSMENT_LOADING";
export const RESULTS_LOADING = "RESULTS_LOADING";
export const RESULTS_LOADED = "RESULTS_LOADED";
export const ASSESSMENT_LOADED = "ASSESSMENT_LOADED";
export const QUESTION_ANSWERED = "QUESTION_ANSWERED";

const error = error => ({ type: ASSESSMENT_FAILURE, error });
const loading = loading => ({ type: ASSESSMENT_LOADING, loading: !!loading });
const loadingResults = loading => ({ type: RESULTS_LOADING, loading: !!loading });
const loaded = payload => ({ type: ASSESSMENT_LOADED, payload: !payload ? null : payload });
const answerQuestion = (assessment, question, answer) => ({ type: QUESTION_ANSWERED, payload: { assessment, question, answer } });
const loadResults = (assessment, results, metadata = {}) => ({
    type: RESULTS_LOADED,
    payload: { assessment, metadata, results: results === [] ? {} : results }
});

const putAssessmentResults = (reference, formData, formName) => {
    if (!reference) {
        throw new Error("reference must be defined!");
    }
    if (formData === undefined || formData === {}) {
        throw new Error("no data provided");
    }
    const selector = formValueSelector(formName);
    return (dispatch, getState) => {
        const id = getIdFromIri(reference);
        const {
            login: {
                authenticated: { instrument }
            },
            instrument: { instruments },
            assessment: { tabs }
        } = getState();

        const questions = instruments.find(({ code }) => code === instrument).items;
        const outFormData = Object.keys(formData).reduce((outPages, pageName) => {
            outPages[pageName] = Object.keys(formData[pageName]).reduce((outPage, questionName) => {
                const question = questions.find(q => q.code === questionName);
                if (
                    question !== undefined &&
                    question.condition !== undefined &&
                    selector(getState(), question.condition.field) !== question.condition.value
                ) {
                    return outPage;
                }
                const value = formData[pageName][question.code];
                if (Array.isArray(value) && value.length === 0) {
                    return outPage;
                }
                const outQuestion = {
                    code: question.code,
                    position: question.position,
                    label: question.descriptiveTag,
                    value: value,
                    choice_code: (question.choices.find(choice => `${choice.value}` === `${value}`) || {}).code
                };
                if (Array.isArray(value)) {
                    return {
                        ...outPage,
                        [questionName]: {
                            ...outQuestion,
                            valueLabel: value.map(v => ({
                                value: v,
                                label: question.choices.find(choice => `${choice.value}` === `${v}`).descriptiveTag,
                                choice_code: question.choices.find(choice => `${choice.value}` === `${v}`).code
                            }))
                        }
                    };
                }
                if (question.type === "MULTICHOICE") {
                    return {
                        ...outPage,
                        [questionName]: {
                            ...outQuestion,
                            valueLabel: Object.keys(value).map(v => {
                                const choice = question.choices.find(choice => `${choice.code}` === `${v}`);
                                return { code: v, value: choice.value, label: choice.descriptiveTag };
                            })
                        }
                    };
                }
                return {
                    ...outPage,
                    [questionName]: {
                        ...outQuestion,
                        valueLabel: question.choices.find(choice => `${choice.value}` === `${value}`).descriptiveTag
                    }
                };
            }, {});
            return outPages;
        }, {});
        const final = getState().assessment.final;

        let data = {
            answers: outFormData,
            extra: { status: final ? "COMPLETED" : "SAVED", metadata: { tabs } }
        };
        return fetch(`/instrument_result/results/${id}`, data, "PUT")
            .then(data => {
                if (data.extra && data.extra.errors === true && final === true) {
                    dispatch(
                        flashMessage("Vragenlijst opgeslagen, maar nog niet compleet. Vul alle verplichte vragen in", { props: { color: "danger" } })
                    );
                } else {
                    dispatch(flashMessage("Vragenlijst opgeslagen"));
                }
                if (data.extra && data.extra.status.toLowerCase() === "completed") {
                    dispatch(logout());
                }
            })
            .catch(e => {
                dispatch(error(e._error.error));
            });
    };
};

const getAssessmentResults = reference => {
    if (!reference) {
        throw new Error("reference must be defined!");
    }
    return (dispatch, state) => {
        dispatch(loadingResults(true));
        const id = getIdFromIri(reference);
        return fetch(`/api/instrument_result/results/${id}`)
            .then(data => {
                if (data.answers) {
                    dispatch(loadResults(reference, data.answers, data.extra));
                }
            })
            .catch(e => {
                dispatch(error(e._error.error));
            });
    };
};

export { error, loading, loadingResults, loadResults, putAssessmentResults, getAssessmentResults, loaded, answerQuestion };
