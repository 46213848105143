import { combineReducers } from "redux";

const error = (state = null, action = {}) => (action.type === "ASSESSMENT_FAILURE" ? action.error : state);
const loadingResults = (state = false, action = {}) => {
    switch (action.type) {
        case "RESULTS_LOADING":
            return action.loading;
        case "ASSESSMENT_FAILURE":
            return false;
        case "RESULTS_LOADED":
            return false;
        default:
            return state;
    }
};
const loadedResults = (state = false, action = {}) => {
    switch (action.type) {
        case "ASSESSMENT_FAILURE":
            return true;
        case "ASSESSMENT_LOADED":
        case "RESULTS_LOADED":
            return true;
        default:
            return state;
    }
};
const tabLabels = {
    cover: "Voorblad",
    additional: "Basis"
};

const tabs = (state = [], action = {}) => {
    if (action.type === "RESULTS_LOADED") {
        const { tabs } = (action.payload.metadata || { assessment: { tabs: [] } }).assessment || {};
        return (tabs || state).sort((a, b) => a.order - b.order) || state;
    }
    if (action.type === "INSTRUMENT_LOADED") {
        if (action.instrument.extra && action.instrument.extra.pages) {
            return action.instrument.extra.pages;
        }
        const tabLabelsInternal = (action.instrument.extra || {}).pages || tabLabels;
        return action.instrument.items
            .map(item => {
                return item.extra.page || "unknown";
            })
            .reduce((out, tab) => {
                if (out.indexOf(tab) === -1) {
                    out.push(tab);
                }
                return out;
            }, [])
            .map((tab, index) => ({ code: tab, label: tabLabelsInternal[tab] || tab, number: index }));
    }
    return state;
};
const data = (state = { gender: null }, action = {}) => {
    if (action.type !== "RESULTS_LOADED") {
        return state;
    }
    const { data } = (action.payload.metadata || { assessment: { data: {} } }).assessment || {};
    return { gender: state.gender, ...data };
};
const final = (state = false, action = {}) => (action.type !== "FORM_FINAL" ? state : !!action.final);
const answers = (state = {}, action = {}) => {
    if (action.type !== "RESULTS_LOADED") {
        return state;
    }
    const tResults = action.payload.results || {};
    const results = Object.keys(tResults).reduce((out, pageKey) => {
        if (tResults[pageKey] instanceof Array) {
            out[pageKey] = {};
            return out;
        }
        const pageValues = tResults[pageKey];
        out[pageKey] = Object.keys(pageValues).reduce((out, questKey) => ({ ...out, [questKey]: pageValues[questKey].value }), {});
        return out;
    }, {});
    const { initialValues } = ((action.payload.metadata || {}).assessment || {}).data || { initialValues: {} };
    return { ...initialValues, ...results };
};
export default combineReducers({ error, data, final, answers, loadedResults, loadingResults, tabs });
