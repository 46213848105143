import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import CenterBlock from "components/shared/CenterBlock";
import { FormattedMessage, injectIntl } from "react-intl";
import { doAssessmentAuthenticate } from "_actions/security/login";
import { useEffect } from "react";
import { useParams } from "react-router";

const AssessmentAuthenticateComponent = ({ authenticate, errors, authenticating, isLoggedin, hasAssessment }) => {
    const message = () => {
        if (errors.login) {
            return this.props.intl.formatMessage({ id: `error.login.${errors.login}`, defaultMessage: errors.login });
        }
        if (authenticating) {
            return <FormattedMessage id="loading.authenticating" defaultMessage="loading" />;
        }
        return <FormattedMessage id="loading.general" defaultMessage="loading" />;
    };
    const params = useParams();
    useEffect(() => {
        if (isLoggedin) return () => {};
        const timer = setTimeout(() => {
            const { token } = params;
            authenticate(token);
        }, 1500);

        return () => {
            clearTimeout(timer);
        };
    }, [params, isLoggedin, authenticate]);
    if (isLoggedin && hasAssessment) return <Redirect to={`/assessment/${hasAssessment}`} />;
    if (isLoggedin) return <Redirect to="/" />;
    return <CenterBlock message={message()} />;
};

const mapStateToProps = ({ login: { authenticated, error, loading } }) => ({
    isLoggedin: !!(authenticated && authenticated.token),
    hasAssessment: authenticated && authenticated.assessment,
    authenticating: !!loading,
    errors: { login: error }
});

const dispatchToProps = dispatch => ({ authenticate: token => dispatch(doAssessmentAuthenticate({ token })) });

const AssessmentAuthenticate = connect(mapStateToProps, dispatchToProps)(injectIntl(AssessmentAuthenticateComponent));

export { AssessmentAuthenticate };
