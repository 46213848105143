import { axiosInstance, convertUri } from "_utils/fetch";
import { push } from "connected-react-router";

export const LOGIN_START = "SECURITY_LOGIN_START";
export const LOGIN_FAILURE = "SECURITY_LOGIN_ERROR";
export const LOGIN_LOADING = "SECURITY_LOGIN_LOADING";
export const LOGIN_SUCCESS = "SECURITY_LOGIN_SUCCESS";
export const LOGIN_JWT_EXPIRED = "SECURITY_JWT_EXPIRED";

const fetch = data => axiosInstance({})({ ...data, url: convertUri(data.url) });

export const success = authenticated => {
    if (authenticated) sessionStorage.setItem("APP_TOKENS", JSON.stringify(authenticated));
    return { type: LOGIN_SUCCESS, authenticated };
};
export const logout = () => {
    sessionStorage.removeItem("APP_TOKENS");
    sessionStorage.removeItem("APP_ACCOUNT");
    return dispatch => {
        dispatch(success({}));
        dispatch(push("/logged_out"));
    };
};

export const reset = () => logout();

export const jwtExpired = ({ refreshToken }) => {
    return dispatch => {
        return fetch({ method: "POST", url: "/security/refresh", data: { refresh_token: refreshToken } })
            .then(data => dispatch(success(data)))
            .catch(() => dispatch(reset()));
    };
};

export const doAssessmentAuthenticate = ({ token }) => {
    return dispatch => {
        dispatch({ type: LOGIN_START });

        return fetch({ method: "POST", url: "/security/assessmentLogin", data: { code: token } })
            .then(({ data }) => {
                dispatch(success(data));
                dispatch(push("/"));
            })
            .catch(({ response: { data } }) => {
                dispatch({ type: LOGIN_FAILURE, ...data });
            });
    };
};
