import React from "react";

export default ({ error, meta }) => {
    const isInvalid = !!error || (meta.touched && !!meta.error);
    return [
        isInvalid &&
            error &&
            error.map((e, i) => (
                <div key={i} className="invalid-feedback">
                    {e}
                </div>
            )),
        isInvalid && meta.error && (
            <div key="general-error" className="invalid-feedback">
                {meta.error}
            </div>
        )
    ];
};
