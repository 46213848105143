import React, { Component } from "react";
import { ReduxCheckbox, Checkboxes as CheckBoxBS } from "react-form-checkbox";
import { FormFeedback } from "instruments/widgets";

class Checkboxes extends Component {
    render() {
        const { noLabel, ...props } = this.props;
        return (
            <>
                <CheckBoxBS
                    {...props}
                    itemComponent={({ input, label, data }) => {
                        const current = data.find(d => d.value === input.value);
                        return [
                            <div key="check" className="form-check">
                                <label className="form-check-label">
                                    <input
                                        type={props.extra.viewdata.multiple === "true" ? "checkbox" : "radio"}
                                        className="form-check-input"
                                        {...input}
                                        name={props.name}
                                    />

                                    {noLabel === true ? "" : label}
                                </label>
                            </div>,
                            current.withText && input.checked !== 0 && (
                                <div key="text" className="form-group">
                                    <input className="form-control" name={input.name + "_text"} />
                                </div>
                            )
                        ];
                    }}
                />
                <FormFeedback {...{ input: this.props.input, meta: this.props.meta }} />
            </>
        );
    }
}

export { ReduxCheckbox, Checkboxes };
