import React, { Component } from "react";
import "assets/scss/components/LoadingSpinner.scss";
import { Alert } from "reactstrap";

class Spinner extends Component {
    render() {
        const { label, error } = this.props;
        return (
            <div className="loading-wrapper">
                <div className="loading-spinner" />
                {label && (
                    <div className="d-block">
                        <em>{label}</em>
                    </div>
                )}
                {error && (
                    <div className="d-block">
                        <Alert color="danger">{error}</Alert>
                    </div>
                )}
            </div>
        );
    }
}

class CenteredSpinner extends Component {
    render() {
        const { label, error } = this.props;
        return (
            <div className="justify-content-center">
                <Spinner label={label} error={error} />
            </div>
        );
    }
}
export { Spinner, CenteredSpinner };
