import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const CoverPageComponent = ({ title, introduction, subtitle }) => [
    title && (
        <h2 key="title" className="text-center">
            {title}
        </h2>
    ),
    subtitle && (
        <h4 key="subtitle" className="text-center">
            {subtitle}
        </h4>
    ),
    introduction && <p key="introduction" dangerouslySetInnerHTML={{ __html: introduction }} />
];

CoverPageComponent.propTypes = {
    introduction: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string
};

const mapStateToProps = ({ instrument: { instruments } }) => instruments[0] && instruments[0].extra && instruments[0].extra.cover;

const CoverPage = connect(mapStateToProps)(CoverPageComponent);

export default CoverPage;
