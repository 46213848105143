import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import CenterBlock from "components/shared/CenterBlock";
import { Redirect } from "react-router";
import { push } from "connected-react-router";

class LoggedOutPageComponent extends Component {
    componentDidMount() {
        setTimeout(() => this.props.returnHome(), 3000);
    }

    render() {
        const { isLoggedin, hasAssessment, intl } = this.props;
        if (isLoggedin && hasAssessment) return <Redirect to={`/assessment/${hasAssessment}`} />;

        return <CenterBlock message={intl.formatMessage({ id: "fallback.logged-out", defaultMessage: "thanks!" })} />;
    }
}

const mapStateToProps = ({ login: { authenticated } }) => ({
    isLoggedin: !!(authenticated && authenticated.token),
    hasAssessment: authenticated && authenticated.assessment
});
const dispatchToProps = dispatch => ({ returnHome: () => dispatch(push("/")) });

const LoggedOutPage = connect(
    mapStateToProps,
    dispatchToProps
)(injectIntl(LoggedOutPageComponent));
export default LoggedOutPage;
