import React, { Component } from "react";
import { ReduxFormDate } from "instruments/utils/_date";

class DateWidget extends Component {
    render() {
        const { input, type, myProps } = this.props;
        return <ReduxFormDate type={type} input={input} id={`${input.name}`} myProps={myProps} />;
    }
}

export default DateWidget;
