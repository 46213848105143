import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router";

import { CenteredSpinner } from "components/shared/LoadingSpinner";
import { getAssessmentResults, putAssessmentResults } from "_actions/assessment/assessment";
import Questionnaire from "instruments";
import { loadInstrument } from "_actions/assessment/instrument";
import "assets/scss/components/Assessment.scss";
import { useParams } from "react-router";

const QuestionnaireWrapper = ({ children }) => (
    <div className="overflow-scrollable">
        <div className="container mx-auto assessment-wrapper">
            <div className="d-flex row align-items-center">
                <div className="col mx-auto">
                    <div className="card assessment mt-2">
                        <div className="card-body">{children}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const AssessmentFill = ({
    isLoggedin,
    hasAssessment,
    instrumentLoaded,
    fetchInstrument,
    assessmentResults,
    remoteSubmit,
    instrumentCode,
    assessmentUrl,
    getResults
}) => {
    const params = useParams();
    useEffect(() => {
        if (isLoggedin && !assessmentResults) {
            getResults(decodeURIComponent(params.id));
        }
    }, [isLoggedin, assessmentResults, params.id, getResults]);

    if (!isLoggedin) return <Redirect to={`/`} />;

    if (!assessmentResults) {
        return <CenteredSpinner />;
    }
    if (!instrumentLoaded) {
        fetchInstrument(instrumentCode);
        return <CenteredSpinner />;
    }
    if (!hasAssessment) return <Redirect to={`/`} />;
    return (
        <QuestionnaireWrapper>
            <Questionnaire formType={instrumentCode} remoteSubmit={remoteSubmit} url={assessmentUrl} />
        </QuestionnaireWrapper>
    );
};

const mapStateToProps = ({ login: { authenticated }, assessment, instrument: { loaded } }) => ({
    isLoggedin: !!(authenticated && authenticated.token),
    hasAssessment: authenticated && authenticated.assessment,
    instrumentLoaded: loaded,
    assessmentUrl: `/assessment/${authenticated.assessment}`,
    instrumentCode: authenticated && authenticated.instrument,
    assessmentResults: !assessment.loadingResults && !!assessment.loadedResults
});
const dispatchToProps = dispatch => ({
    fetchInstrument: code => dispatch(loadInstrument(code)),
    getResults: (id, reference) => dispatch(getAssessmentResults(id, reference)),
    remoteSubmit: ({ url, final, formName = "generic_questionnaire", ...formData }) => {
        dispatch(putAssessmentResults(url, formData, formName));
    }
});

AssessmentFill.propTypes = {
    getResults: PropTypes.func.isRequired,
    isLoggedin: PropTypes.bool.isRequired,
    hasAssessment: PropTypes.number,
    instrumentLoaded: PropTypes.bool.isRequired,
    assessmentResults: PropTypes.bool.isRequired,
    fetchInstrument: PropTypes.func.isRequired,
    instrument: PropTypes.string
};

export const AssessmentFillPage = connect(mapStateToProps, dispatchToProps)(AssessmentFill);
