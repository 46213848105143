import React from "react";
import { connect } from "react-redux";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import NavLink from "reactstrap/lib/NavLink";
import { FaExclamationTriangle } from "react-icons/fa";
import { getFormSyncErrors, hasSubmitFailed } from "redux-form";

const InstrumentTabItemComponent = ({ onClick, number, active, label, valid }) => (
    <NavItem key={number} active={active}>
        <NavLink className="rounded-0" onClick={onClick}>
            {label}
            {!valid && (
                <>
                    <span> </span>
                    <FaExclamationTriangle className={"text-danger"} />
                </>
            )}
        </NavLink>
    </NavItem>
);

const mapItemStateToProps = (state, { code }) => {
    const valid = getFormSyncErrors("generic_questionnaire")(state)[code] || true;
    const submitted = hasSubmitFailed("generic_questionnaire")(state);
    return { valid: !submitted || valid === true };
};
const InstrumentTabItem = connect(mapItemStateToProps)(InstrumentTabItemComponent);

const TabBarComponent = ({ tabs, activeTab, activatePage }) => (
    <Nav key="headers" tabs className="col-12 border-primary">
        {tabs.map(({ code, ...tab }) => (
            <InstrumentTabItem key={code} onClick={() => activatePage(code)} active={activeTab === `${code}`} {...{ ...tab, code }} />
        ))}
    </Nav>
);

const mapFormStateToProps = ({ assessment: { tabs = [] }, visualState: { page }, instrument: { instruments } }, props) => {
    return {
        activeTab: page,
        tabs: props.tabs || tabs
    };
};
const mapPropsToDispatch = (dispatch, { remoteSubmit, url }) => {
    return {
        activatePage: code => dispatch({ type: "PAGE_CHANGE", payload: code })
    };
};
const TabBar = connect(mapFormStateToProps, mapPropsToDispatch)(TabBarComponent);

export default TabBar;
