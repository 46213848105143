import { combineReducers } from "redux";

const authenticated = (state = null, action = {}) => (action.type === "SECURITY_LOGIN_SUCCESS" ? action.authenticated : state);
const error = (state = null, action = {}) => (action.type === "SECURITY_LOGIN_ERROR" ? action.error || action.message : state);

const loading = (state = false, action = {}) => {
    switch (action.type) {
        case "SECURITY_LOGIN_LOADING":
            return action.loading;
        case "SECURITY_LOGIN_START":
            return true;
        case "SECURITY_LOGIN_ERROR":
            return false;
        default:
            return state;
    }
};
export default combineReducers({ error, authenticated, loading });
