import React from "react";

export default ({ input, placeholder, options }) => {
    return (
        <select {...input} autoComplete="off" id={`${input.name}`}>
            {placeholder !== false && <option>{placeholder || "-- Selecteer --"}</option>}
            {options.map(choice => (
                <option key={choice.value} value={choice.value}>
                    {choice.label}
                </option>
            ))}
        </select>
    );
};
