import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

class ButtonComponent extends Component {
    render() {
        const { disabled, onClick, labelKey, bsStyle = "btn-primary" } = this.props;
        return (
            <button type="button" {...{ disabled, onClick, className: `btn ${bsStyle}` }}>
                {this.props.intl.formatMessage({ id: labelKey, defaultMessage: labelKey })}
            </button>
        );
    }
}

const Button = injectIntl(ButtonComponent);

class ButtonBarComponent extends Component {
    render() {
        const { submitting, nonAgreement, pageFirst, pageLast, saveFinal, saveTemporary, prevPage, nextPage, navigatePage, isCover } = this.props;
        return [
            <div key="button-bar">
                <div key="left-prev-page" className="button-bar mt-3 mr-1 float-left">
                    {!isCover && !pageFirst && (
                        <Button
                            disabled={pageFirst}
                            onClick={() => navigatePage(prevPage)}
                            bsStyle="btn-outline-primary"
                            labelKey="assessment.button.previous"
                        />
                    )}
                </div>
                <div key="left-save-temp" className="button-bar mt-3 mr-1 float-left">
                    {!isCover && !pageLast && (
                        <Button disabled={submitting || pageLast} onClick={saveTemporary} labelKey="assessment.button.temporary" />
                    )}
                </div>
                <div key="right-next-page" className="button-bar mt-3 ml-1 float-right">
                    {!isCover && !pageLast && (
                        <Button
                            disabled={pageLast}
                            bsStyle="btn-outline-primary"
                            onClick={() => navigatePage(nextPage)}
                            labelKey="assessment.button.next"
                        />
                    )}
                </div>
                <div key="right-save-final" className="button-bar mt-3 ml-1 float-right">
                    {pageLast && !isCover && <Button disabled={submitting || nonAgreement} onClick={saveFinal} labelKey="assessment.button.final" />}
                </div>
                <div key="right-start" className="button-bar mt-3 ml-1 float-right">
                    {isCover && <Button bsStyle="btn-outline-primary" onClick={() => navigatePage(nextPage)} labelKey="assessment.button.start" />}
                </div>
            </div>
        ];
    }
}

const mapFormStateToProps = ({ assessment: { tabs }, visualState: { page } }, props) => {
    tabs = props.tabs || tabs;
    const currentPageOrder = (tabs.find(({ code }) => code === page) || {}).number || 0;
    return {
        nonAgreement: false,
        isCover: (tabs.find(({ code }) => code === page) || {}).code === "cover" || page === "cover",
        pageFirst: currentPageOrder === 0,
        nextPage: (tabs.find(({ number }) => number === currentPageOrder + 1) || {}).code,
        prevPage: (tabs.find(({ number }) => number === currentPageOrder - 1) || {}).code,
        pageLast: currentPageOrder === tabs.reduce((max, tab) => (tab.number > max ? tab.number : max), 0)
    };
};
const mapPropsToDispatch = (dispatch, { remoteSubmit }) => {
    return {
        saveTemporary: () => remoteSubmit({ final: false }),
        saveFinal: () => remoteSubmit({ final: true }),
        navigatePage: number => dispatch({ type: "PAGE_CHANGE", payload: number })
    };
};
const ButtonBar = connect(mapFormStateToProps, mapPropsToDispatch)(ButtonBarComponent);

export default ButtonBar;
