import React from "react";
import BsToggle from "instruments/utils/widgets/BsToggle";

export default ({ disabled, input, options }) => {
    input.className = "form-check-input";
    if (disabled === true) {
        input.disabled = "disabled";
    }
    return (
        <BsToggle
            key="switch"
            enabled={!!input.value}
            name={input.name}
            onStateChanged={({ enabled }) => input.onChange(enabled)}
            negativeLabel={(options.find(({ value }) => value === false) || {}).label || "\u00A0"}
            positiveLabel={(options.find(({ value }) => value === true) || {}).label || "\u00A0"}
        />
    );
};
