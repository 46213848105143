import React from "react";

export default ({ disabled, input, type, label }) => {
    input.className = "form-check-input";
    if (disabled === true) {
        input.disabled = "disabled";
    }
    return (
        <div className={`form-check` + (disabled ? " disabled" : "")}>
            <input {...input} type={type} id={`${input.name}-${input.value}`} />
            <label htmlFor={`${input.name}-${input.value}`} className="form-control-label">
                {label || "\u00A0"}
            </label>
        </div>
    );
};
