import React, { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
import fecha from "fecha";
import "@fortawesome/fontawesome-free/css/all.css";
import { toDate } from "_utils";
registerLocale("nl", nl);

class PickerWithIcon extends Component {
    constructor(props) {
        super(props);
        this._handleChange = this._handleChange.bind(this);
    }
    state = { value: this.props.value, valueForce: this.props.value };
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.value === prevState.valueForce) {
            return null;
        }
        return {
            value: nextProps.value,
            valueForce: nextProps.value
        };
    }

    _handleChange(value, extended = false) {
        this.setState({ value: value });
        if (!value.match(/^[0123][0-9]-[01][0-9]-\d{4}$/)) {
            if (extended && !value.match(/^[0123]{0,1}[0-9]-[01]{0,1}[0-9]-\d{4}$/)) {
                return true;
            }
            if (!extended) {
                return true;
            }
        }
        const newDate = fecha.parse(value, "DD-MM-YYYY");
        this.props.onChange({ target: { value: newDate } });
    }

    render() {
        const { disabled, readOnly, placeholder, className, error, invalid } = this.props;
        const { value } = this.state;
        return (
            <div className="input-group" onClick={this.props.onClick}>
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className="far fa-calendar-alt" />
                    </span>
                </div>
                <input
                    type="text"
                    {...{ value, disabled, readOnly, placeholder, className }}
                    onChange={({ target: { value } }) => this._handleChange(value)}
                    onBlur={({ target: { value } }) => this._handleChange(value, true)}
                />
                {invalid && error && <div className="invalid-feedback">{error}</div>}
            </div>
        );
    }
}
export const ReduxFormDate = ({ input, showTime, showDate, type, myProps = {}, placeholder }) => {
    if (showDate === undefined) {
        showDate = type === "datetime" || type === "date";
    }
    if (showTime === undefined) {
        showTime = type === "datetime" || type === "time";
    }
    input.value = toDate(input.value);

    if (myProps.maxDate) {
        myProps.maxDate = toDate(myProps.maxDate);
    }
    if (myProps.minDate) {
        myProps.minDate = toDate(myProps.minDate);
    }
    return (
        <DatePicker
            locale="nl"
            showTimeSelect={showTime}
            timeFormat="HH:mm"
            disabled={input.disabled === "disabled" || input.disabled}
            dateFormat={showTime && showDate ? `dd-MM-yyyy H:mm` : showDate ? `dd-MM-yyyy` : "H:mm"}
            maxDate={new Date()}
            showTimeSelectOnly={!showDate}
            showDisabledMonthNavigation
            showYearDropdown
            name={input.name}
            placeholderText={placeholder}
            customInput={<PickerWithIcon error={myProps.error} invalid={myProps.invalid} />}
            showMonthDropdown
            selected={input.value === "" ? null : input.value}
            className={input.className}
            onChange={input.onChange}
            {...myProps}
        />
    );
};
