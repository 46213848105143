import React, { useEffect } from "react";
import { connect } from "react-redux";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import { CoverPage, QuestionPage } from "instruments/pages";
import TabBar from "instruments/components/TabBar";

const FormComponent = ({ activeTab, tabs, currentTabExists, activatePage }) => {
    useEffect(() => {
        try {
            document.getElementById(`tab-pane-wrapper`).scroll({ top: 0, left: 0, behavior: "smooth" });
        } catch (error) {
            try {
                document.getElementById(`tab-pane-wrapper`).scrollTo(0, 0);
            } catch (error) {
                document.getElementById(`tab-pane-wrapper`).scrollTop = 0;
            }
        }
    }, [activeTab]);
    useEffect(() => {
        activatePage(tabs[0].code);
    }, [activatePage, tabs]);
    return [
        <TabBar key="headers" tabs={tabs} />,
        <TabContent key="contents" id="tab-pane-wrapper" activeTab={`${activeTab}`} className="col-12">
            {tabs.map(({ number, code }) => (
                <TabPane key={number} tabId={code} id={`page-${code}`}>
                    {code === "cover" && <CoverPage />}
                    {code !== "cover" && <QuestionPage page={code} />}
                </TabPane>
            ))}
        </TabContent>
    ];
};

const mapFormStateToProps = ({ visualState: { page }, assessment: { tabs = [] } }) => {
    const currentTabExists = tabs.find(t => t.number === page) !== null;
    return {
        activeTab: page,
        currentTabExists,
        tabs
    };
};
const dispatchFormToProps = dispatch => ({
    activatePage: page => dispatch({ type: "PAGE_CHANGE", payload: page })
});

export default connect(mapFormStateToProps, dispatchFormToProps)(FormComponent);
