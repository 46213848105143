import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

class NoticeBlockComponent extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        notice: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        conditionMet: PropTypes.bool.isRequired,
        condition: PropTypes.shape({
            field: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            values: PropTypes.array
        })
    };
    render() {
        if (!this.props.conditionMet) {
            return null;
        }
        return <div dangerouslySetInnerHTML={{ __html: this.props.label }} />;
    }
}

const selector = formValueSelector("generic_questionnaire");

const mapStateToProps = (state, ownProps) => {
    const { name, ...ft } = state.assessment.field_types.find(({ name }) => name === ownProps.type) || {};
    return {
        ...ownProps,
        ...(ft || {}),
        conditionMet: !ownProps.condition || selector(state, ownProps.condition.field) === ownProps.condition.value
    };
};
const mapPropsToDispatch = dispatch => ({});

export default connect(mapStateToProps, mapPropsToDispatch)(NoticeBlockComponent);
