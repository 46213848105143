import React, { Component } from "react";
import { NavbarBrand, Navbar } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as loginActions from "_actions/security/login";
import logo from "assets/images/odis_logo.png";
import "assets/scss/components/Navbar.scss";
import { flashMessage } from "redux-flash";
import { push } from "connected-react-router";

class NavigationMenu extends Component {
    static propTypes = {
        isLoggedin: PropTypes.bool.isRequired,
        logout: PropTypes.func.isRequired,
        showHome: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
    }
    state = {
        isOpen: false
    };
    toggle() {
        this.setState({ isOpen: !this.state.isOpen });
    }
    render() {
        if (!this.props.isLoggedin) {
            return null;
        }
        return (
            <Navbar color="light" light expand="md">
                <NavbarBrand onClick={this.props.showHome}>
                    <img className="mx-2 img-fluid" src={logo} alt="logo" />
                </NavbarBrand>
            </Navbar>
        );
    }
}

const mapStateToProps = ({ login: { authenticated } }) => {
    return {
        isLoggedin: !!(authenticated && authenticated.token)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showHome: () => {
            dispatch(push("/"));
        },
        logout: () => {
            dispatch(loginActions.reset());
            dispatch(flashMessage("U bent succesvol uitgelogd."));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMenu);
