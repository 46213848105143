import { combineReducers } from "redux";

const instrument = (state = [], action = {}) => {
    if (action.type === "SECURITY_LOGOUT") {
        return null;
    }
    if (action.type === "INSTRUMENTS_LOADED") {
        return action.instruments.map(instrument => ({ ...instrument }));
    }
    if (action.type === "INSTRUMENT_LOADED" || action.type === "INSTRUMENT_UPDATED") {
        const { instrument } = action;
        return [...state.filter(({ id }) => id !== instrument.id), instrument];
    }
    return state;
};

const loaded = (state = false, action = {}) => {
    if (action.type === "SECURITY_LOGOUT") {
        return false;
    }
    if (action.type === "INSTRUMENTS_LOADED") {
        return true;
    }
    if (action.type === "INSTRUMENT_LOADED") {
        return true;
    }
    return state;
};

export default combineReducers({ loaded, instruments: instrument });
