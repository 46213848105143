import React, { Component } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getFlashMessages } from "redux-flash";
import flashMessageType from "redux-flash/lib/flashMessageType";

class FlashMessage extends Component {
    static propTypes = {
        flash: flashMessageType
    };
    get color() {
        const {
            flash: { isError, props: { color = "success" } = { color: "success" } }
        } = this.props;
        return isError ? "danger" : color;
    }
    render() {
        const { flash } = this.props;
        return <div className={`col-12 mx-auto alert alert-${this.color}`}>{flash.message}</div>;
    }
}
class FlashMessages extends Component {
    static propTypes = {
        flashes: PropTypes.array
    };

    render() {
        let { flashes } = this.props;
        if (!flashes || flashes.length === 0) {
            return null;
        }

        return (
            <div className="container fixed-top" style={{ marginTop: "75px" }}>
                <div className="row ">{flashes.map(flash => flash && <FlashMessage key={flash.id} flash={flash} />)}</div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        flashes: getFlashMessages(state)
    };
};

export default connect(mapStateToProps)(FlashMessages);
