import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import CenterBlock from "components/shared/CenterBlock";
import { Redirect } from "react-router";

class MessagePageComponent extends Component {
    render() {
        const { isLoggedin, hasAssessment, intl } = this.props;
        if (isLoggedin && hasAssessment) return <Redirect to={`/assessment/${hasAssessment}`} />;
        return <CenterBlock message={intl.formatMessage({ id: "fallback.no-login", defaultMessage: "no page" })} />;
    }
}

const mapStateToProps = ({ login: { authenticated } }) => ({
    isLoggedin: !!(authenticated && authenticated.token),
    hasAssessment: authenticated && authenticated.assessment
});
const dispatchToProps = dispatch => ({});

const MessagePage = connect(
    mapStateToProps,
    dispatchToProps
)(injectIntl(MessagePageComponent));

export default MessagePage;
