import { SubmissionError } from "redux-form";
import { API_HOST, API_PATH, APP_LANGUAGE } from "./_entrypoint";
import axios from "axios";
import HttpStatus from "http-status-codes";
import { store } from "_utils/store";
import { jwtExpired, logout } from "_actions/security/login";
import jwtDecode from "jwt-decode";
const jsonLdMimeType = "application/json";

const resources = {};

const verifyJwt = () => {
    const tokens = store.getState().login && store.getState().login.authenticated;
    if (!tokens || !tokens.token) {
        return;
    }
    if (jwtDecode(tokens.token).exp > Math.floor(new Date().getTime() / 1000)) {
        return;
    }
    store.dispatch(jwtExpired({ refreshToken: tokens.refresh_token }));
};

export const axiosInstance = headers => {
    let token =
        headers.Authorization !== false
            ? store.getState().login && store.getState().login.authenticated && store.getState().login.authenticated.token
            : null;
    return axios.create({
        timeout: 30000,
        headers: {
            Accept: jsonLdMimeType,
            "Content-Type": jsonLdMimeType,
            "ui-locale": APP_LANGUAGE,
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
            ...headers
        }
    });
};

export const convertUri = uri => (uri.includes(API_PATH) ? API_HOST + uri : API_HOST + API_PATH + uri);

const violationMapper = (inObject, violations) =>
    violations.reduce((out, { propertyPath, message }) => {
        if (!out._error[propertyPath]) {
            out._error[propertyPath] = [];
        }
        out._error[propertyPath].push(message);
        return out;
    }, inObject);

export default function(url, data = {}, method = "GET", headers = {}) {
    if (headers.Authorization !== false) {
        verifyJwt();
    }
    const link = convertUri(url);
    if (resources[`${method}_${link}`]) {
        return Promise.resolve(resources[`${method}_${link}`]);
    }

    resources[`${method}_${link}`] = axiosInstance(headers)({ method, url: link, data })
        .then(({ data }) => {
            delete resources[`${method}_${link}`];
            return data;
        })
        .catch(({ response }) => {
            if (response === undefined) {
                return Promise.reject({ _error: { error: "Server fout", statusCode: 500 } });
            }
            const { data, status } = response;
            delete resources[`${method}_${link}`];
            if ((status === 401 || status === 403) && headers.Authorization !== false) {
                store.dispatch(logout());
                return Promise.reject({ _error: { error: HttpStatus.getStatusText(status), statusCode: status } });
            }
            if (!data) {
                return Promise.reject({ _error: { error: HttpStatus.getStatusText(status), statusCode: status } });
            }
            const error = {
                error: data["hydra:description"] || (data.code ? data.message : ""),
                statusCode: status
            };
            if (!data.violations) {
                throw new SubmissionError({ _error: error });
            }
            throw new SubmissionError(violationMapper({ _error: { error: "Verzenden mislukt", statusCode: status } }, data.violations));
        });
    return resources[`${method}_${link}`];
}
