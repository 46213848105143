const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        window.location.hostname === "[::1]" ||
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export default function register() {
    if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location);
        if (publicUrl.origin !== window.location.origin) {
            return;
        }
        if (window.location.href.match(/api/g)) {
            return;
        }

        window.addEventListener("load", () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

            if (isLocalhost) {
                checkValidServiceWorker(swUrl);
                navigator.serviceWorker.ready.then(() => {});
            } else {
                registerValidSW(swUrl);
            }
        });
    }
}

function registerValidSW(swUrl) {
    navigator.serviceWorker
        .register(swUrl)
        .then(registration => {
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === "installed") {
                        if (navigator.serviceWorker.controller) {
                            setTimeout(function() {
                                localStorage.clear();
                                sessionStorage.clear();
                                window.location.reload();
                            }, 5);
                        }
                    }
                };
            };
        })
        .catch(error => {
            console.error("Error during service worker registration:", error);
        });
}

function checkValidServiceWorker(swUrl) {
    fetch(swUrl)
        .then(response => {
            if (response.status === 404 || response.headers.get("content-type").indexOf("javascript") === -1) {
                navigator.serviceWorker.ready.then(registration => {
                    registration.unregister().then(() => {
                        localStorage.clear();
                        sessionStorage.clear();
                        window.location.reload();
                    });
                });
            } else {
                registerValidSW(swUrl);
            }
        })
        .catch(() => {});
}

export function unregister() {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister();
        });
    }
}
