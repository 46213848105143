import React, { Component } from "react";
import { connect } from "react-redux";
import * as routes from "_routes";
import PropTypes from "prop-types";
import { CenteredSpinner } from "components/shared/LoadingSpinner";
import { Switch, Route, Redirect, Router } from "react-router";

class App extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        error: PropTypes.string,
        isLoggedin: PropTypes.bool.isRequired,
        retrieved: PropTypes.object
    };

    render() {
        const { loading, history, isLoggedin } = this.props;
        if (loading) {
            return <CenteredSpinner />;
        }
        if (!isLoggedin) {
            return (
                <Router history={history}>
                    <Switch>
                        {routes.unsecuredRoutes}
                        {<Route render={() => <Redirect to={"/login"} />} />}
                    </Switch>
                </Router>
            );
        }
        let routeList = [];
        routeList = routes.unsecuredRoutes;
        routeList = routeList.concat(routes.userRoutes);
        routeList = routeList.concat(<Route key="fallback-404" render={() => <Redirect to={"."} />} />);
        return (
            <Router history={history}>
                <Switch>{routeList}</Switch>
            </Router>
        );
    }
}

const mapStateToProps = ({ login: { authenticated } }) => {
    return {
        isLoggedin: !!(authenticated && authenticated.token)
    };
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
