import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import FormBlock from "instruments/components/FormBlock";

const QuestionPageComponent = ({ title, introduction, subtitle, closure, page }) => {
    return (
        <div>
            {title && (
                <h2 key="title" className="text-center">
                    {title}
                </h2>
            )}
            {subtitle && (
                <h4 key="subtitle" className="text-center">
                    {subtitle}
                </h4>
            )}
            {introduction && <p key="introduction" dangerouslySetInnerHTML={{ __html: introduction }} />}
            <FormBlock page={page} />
            {closure && <p key="closure" dangerouslySetInnerHTML={{ __html: closure }} />}
        </div>
    );
};

QuestionPageComponent.propTypes = {
    introduction: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string
};

const mapStateToProps = ({ assessment: { content = {} } }, { page }) => ({
    page,
    ...(content[page] || {})
});

const QuestionPage = connect(mapStateToProps)(QuestionPageComponent);

export default QuestionPage;
