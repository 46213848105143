import React from "react";
import { OtherWidget, SelectWidget, SwitchWidget, TextareaWidget, DateWidget, Checkboxes, RadioWidget } from "instruments/widgets";

export default options => {
    const { type, meta, error, input, disabled, placeholder } = options;
    const isInvalid = !!error || (meta.touched && !!meta.error);
    let className = "form-control";
    if (isInvalid) {
        className += " is-invalid";
        input["aria-invalid"] = true;
    }
    if (disabled === true) {
        input.disabled = "disabled";
    }

    const props = { ...options, isInvalid, input: { ...input, className, placeholder: input.placeholder || placeholder } };

    if (type === "date" || type === "datetime" || type === "time") {
        return <DateWidget {...props} />;
    }
    if (type === "textarea") {
        return <TextareaWidget key="field" {...props} />;
    }
    if (type === "switch") {
        return <SwitchWidget {...props} />;
    }
    if (type === "radio") {
        return <RadioWidget {...props} input={{ ...props.input, className: "form-check-input" }} />;
    }
    if (type === "choice") {
        const { expanded = false, multiple = false } = props.select_options || {};
        if (!expanded && !multiple) {
            return <SelectWidget {...props} />;
        }
        if (!multiple && expanded) {
            return <Checkboxes {...{ data: props.options, ...props }} />;
        }
        return `unknown select multiple: ${multiple}, expanded ${expanded}`;
    }
    return <OtherWidget {...props} />;
};
